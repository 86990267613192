// File: /src/libs/user-doc-operations.ts

import {doc, setDoc, getDoc} from "firebase/firestore";
import {db} from "./firebase-app.js";
import {UserDoc} from "../types/user-doc";

export async function updateUserDoc(
  userId: string,
  updates: Partial<UserDoc>,
): Promise<void> {
  try {
    const userDocRef = doc(db, "users", userId);
    await setDoc(userDocRef, updates, {merge: true});
    console.log("User document updated successfully");
  } catch (error) {
    console.error("Error updating user document:", error);
    throw error; // Re-throw to allow handling in the component
  }
}

export async function getUserDoc(userId: string): Promise<UserDoc | null> {
  try {
    const userDocRef = doc(db, "users", userId);
    const userDocSnap = await getDoc(userDocRef);

    if (userDocSnap.exists()) {
      return userDocSnap.data() as UserDoc;
    }
    return null;
  } catch (error) {
    console.error("Error fetching user document:", error);
    return null;
  }
}

