import {storage, ref, getDownloadURL} from "./firebase-app";
import {occupationLookup, iscoLookup, naceLookup, isicData} from "../context/occupations-context";
import {openDB} from "idb";

const DB_NAME = "ResourceDB";
const DB_VERSION = 1;
const STORE_NAME = "Resources";

/**
 * Initialize IndexedDB
 */
const dbPromise = openDB(DB_NAME, DB_VERSION, {
  upgrade(db) {
    if (!db.objectStoreNames.contains(STORE_NAME)) {
      db.createObjectStore(STORE_NAME);
    }
  },
});

/**
 * Retrieve resource data from IndexedDB
 */
async function getResource<T>(key: string): Promise<T | undefined> {
  const db = await dbPromise;
  return db.get(STORE_NAME, key);
}

/**
 * Store resource data in IndexedDB
 */
async function setResource<T>(key: string, value: T): Promise<void> {
  const db = await dbPromise;
  await db.put(STORE_NAME, value, key);
}

export async function loadResources(locale: string): Promise<{
  iscoGroupsData: iscoLookup;
  occupationsData: occupationLookup;
  isicData: any;
  naceData: naceLookup
}> {
  const resourceKeys = [
    `/occupation_data/${locale}_ISCOGroups.json`,
    `/occupation_data/${locale}_Occupations.json`,
    `/industry_data/${locale}_ISIC.json`,
    `/industry_data/${locale}_NACE.json`,
  ];

  const [cachedIscoGroupsData, cachedOccupationsData, cachedIsicData, cachedNaceData] =
    await Promise.all(resourceKeys.map((key) => getResource(key)));

  if (cachedIscoGroupsData && cachedOccupationsData && cachedIsicData && cachedNaceData) {
    console.log("All resources loaded from cache");
    return {
      iscoGroupsData: cachedIscoGroupsData as iscoLookup,
      occupationsData: cachedOccupationsData as occupationLookup,
      isicData: cachedIsicData as isicData,
      naceData: cachedNaceData as naceLookup,
    };
  }

  const storageRef = ref(storage);
  const occupationsRef = ref(storageRef, "occupation_data");
  const isicRef = ref(storageRef, "industry_data");
  const naceRef = ref(storageRef, "industry_data");

  const fileRefs = [
    ref(occupationsRef, `${locale}_ISCOGroups.json`),
    ref(occupationsRef, `${locale}_Occupations.json`),
    ref(isicRef, `${locale}_ISIC.json`),
    ref(naceRef, `${locale}_NACE.json`),
  ];

  async function getUrlWithFallback(fileRef: any, fallbackLocale = "en") {
    try {
      return await getDownloadURL(fileRef);
    } catch (error) {
      console.error(
        `Failed to load ${fileRef.name}, falling back to '${fallbackLocale}'.`,
        error,
      );
      const fallbackRef = ref(
        fileRef.parent,
        fileRef.name.replace(locale, fallbackLocale),
      );
      return getDownloadURL(fallbackRef);
    }
  }

  try {
    const urls = await Promise.all(
      fileRefs.map((ref) => getUrlWithFallback(ref)),
    );
    const responses = await Promise.all(urls.map((url) => fetch(url)));
    const jsonData = await Promise.all(responses.map((r) => r.json()));

    const [newIscoGroupsData, newOccupationsData, newISICData, newNACEData] = jsonData;

    await Promise.all([
      setResource(resourceKeys[0], newIscoGroupsData),
      setResource(resourceKeys[1], newOccupationsData),
      setResource(resourceKeys[2], newISICData),
      setResource(resourceKeys[3], newNACEData),
    ]);

    console.log("Remote resources loaded and cached");

    return {
      iscoGroupsData: newIscoGroupsData,
      occupationsData: newOccupationsData,
      isicData: newISICData,
      naceData: newNACEData,
    };
  } catch (error) {
    console.error("Failed to load resources:", error);
    throw error;
  }
}


