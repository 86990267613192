import {LitElement, html, css} from "lit";
import {customElement, state, property, query} from "lit/decorators.js";
import {msg, localized} from "@lit/localize";
import {
  signInWithEmailAndPassword,
  getAuth,
  sendPasswordResetEmail,
  GoogleAuthProvider,
  signInWithPopup,
} from "firebase/auth";
import {googleIcon} from "../icons/IconGoogle.js";
import {linkedinIcon} from "../icons/IconLinkedIn.js";
import {
  auth,
  functions,
  signInWithCustomToken,
  httpsCallable,
} from "../libs/firebase-app.js";

import {router} from "../router.js";

import "@material/web/dialog/dialog.js";
import "@material/web/button/elevated-button.js";
import "@material/web/textfield/filled-text-field.js";
import {FilledTextField} from "@material/web/textfield/internal/filled-text-field.js";

interface LoginResult {
  token: string;
}

@localized()
@customElement("auth-panel")
export class AuthPanel extends LitElement {
  static styles = css`
    :host {
      box-sizing: border-box;
      border-radius: 5px;
      width: 100%;
      max-width: 340px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-start;
      background-color: var(--md-sys-color-secondary-container);
      color: var(--md-sys-color-on-secondary-container);
    }

    a {
      color: var(--md-sys-color-on-secondary-container);
      text-decoration: underline;
    }

    .content {
      box-sizing: border-box;
      width: 100%;
      padding: 10px;
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    md-filled-text-field,
    md-elevated-button,
    .forgot-password {
      width: 100%;
      max-width: 300px;
      margin-top: 1rem;
    }

    #LoginError {
      font-size: 0.875rem;
      display: none;
    }

    .forgot-password {
      font-size: 0.875rem;
      text-align: left;
      align-self: center;
    }

    .separator {
      display: flex;
      align-items: center;
      text-align: center;
      font-size: 0.875rem;
      margin: 10px 0 0 0;
      width: 100%;
    }

    .separator hr {
      flex-grow: 1;
      border: none;
      border-top: 1px solid #aaa;
      margin: 0 10px;
    }

    .separator span {
      width: 30px;
    }

    md-filled-text-field {
      -webkit-user-select: text;
    }

    #toast:not(:popover-open) {
      opacity: 0;
      filter: blur(6px);
      scale: 0.8;
    }

    :popover-open {
      bottom: 16px;
      left: 12px;
      right: 12px;
      padding: 12px 16px;
      border: 0;
      background-color: var(--md-sys-color-secondary);
      color: var(--md-sys-color-on-secondary);
      border-radius: 8px;
      transition: translate 0.8s var(--spring-easing), opacity 0.3s, filter 0.3s,
        scale 0.3s, display 0.4s allow-discrete, overlay 0.4s allow-discrete;
      box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 4px;
    }

    @starting-style {
      #toast {
        translate: 0 180px;
      }
    }

    ::backdrop {
      backdrop-filter: blur(3px);
    }
  `;
  @property({type: Boolean}) open: boolean = false;
  @state() private emailOrPhone: string = "";
  @state() private password: string = "";
  @query("#emailOrPhone", true) private emailOrPhoneField!: FilledTextField;
  @query("#password", true) private passwordField!: FilledTextField;
  @query("#buttonLogin", true) private buttonLogin!: HTMLButtonElement;
  @query("#LoginError", true) private loginError!: HTMLDivElement;

  async login() {
    this.loginError.style.display = "none";
    this.emailOrPhoneField.error = false;
    this.passwordField.error = false;
    this.buttonLogin.disabled = true;
    const validEmailOrPhone =
      this.isValidEmail(this.emailOrPhone) ||
      this.isValidPhoneNumber(this.emailOrPhone);
    const validPassword = this.isValidPassword(this.password);
    if (!validEmailOrPhone) {
      console.error("Invalid email or phone number");
      this.emailOrPhoneField.error = true;
      this.emailOrPhoneField.reportValidity();
    }
    if (!validPassword) {
      console.error("Invalid password");
      this.passwordField.error = true;
      this.passwordField.reportValidity();
    }
    if (!validEmailOrPhone || !validPassword) {
      this.buttonLogin.disabled = false;
      return;
    }
    try {
      if (this.isValidEmail(this.emailOrPhone)) {
        await signInWithEmailAndPassword(
          auth,
          this.emailOrPhone,
          this.password,
        );
      } else {
        await this.loginWithPhoneNumber(this.emailOrPhone, this.password);
      }
    } catch (error) {
      this.loginError.style.display = "block";
      console.error(error);
    } finally {
      this.buttonLogin.disabled = false;
    }
  }

  private isValidEmail(email: string): boolean {
    const re =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }
  private requestPasswordResetEmail(email: string) {
    if (this.isValidEmail(email)) {
      this.sendPasswordResetEmail(email);
    } else {
      this.emailOrPhoneField.error = true;
      this.emailOrPhoneField.reportValidity();
      console.error("Invalid email or phone number");
    }
  }

  private sendPasswordResetEmail(email: string) {
    if (this.isValidEmail(email)) {
      sendPasswordResetEmail(auth, email)
        .then(() => {
          console.log("Password reset email sent");
        })
        .catch((_error) => {
          const popover = this.shadowRoot?.getElementById("toast");
          if (popover) {
            popover.showPopover();
            setTimeout(() => {
              popover.hidePopover();
            }, 8000);
          }
          // console.error('Error sending password reset email:', error);
        });
    }
  }

  private isValidPhoneNumber(phoneNumber: string): boolean {
    const re = /^\+?[1-9]\d{1,14}$/; // E.164 format
    return re.test(phoneNumber);
  }
  private isValidPassword(password: string): boolean {
    // At least 8 characters long and include at least one lowercase letter, one uppercase letter, one digit, and one special character.
    // const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
    //return passwordRegex.test(password);
    return password.length > 0;
  }
  async reportValidity(event: Event) {
    const textField = event.target as FilledTextField;
    textField.reportValidity();
  }

  // Define the loginWithPhoneNumber function
  async loginWithPhoneNumber(phoneNumber: string, password: string) {
    const loginWithPhoneNumber = httpsCallable(
      functions,
      "loginWithPhoneNumber",
    );

    try {
      const result = await loginWithPhoneNumber({phoneNumber, password});
      const data = result.data as LoginResult;
      const token = data.token;

      await signInWithCustomToken(auth, token);
      console.log("User logged in successfully");
    } catch (error) {
      console.error("Error logging in:", error);
    }
  }
  private signInWithGoogle() {
    const provider = new GoogleAuthProvider();
    provider.addScope("https://www.googleapis.com/auth/contacts.readonly");
    const auth = getAuth();
    auth.languageCode = navigator.language;
    signInWithPopup(auth, provider);
  }
  private initiateLinkedInLogin() {
    router.navigate(`${(import.meta as any).env.BASE_URL}auth/linkedin`);
  }

  render() {
    return html`
      <div class="content">
        <md-filled-text-field
          id="emailOrPhone"
          type="email"
          inputmode="email"
          label=${msg("Email or Phone")}
          aria-label=${msg("Email address or phone number")}
          required
          error-text=${msg("Please enter a valid email address")}
          @input="${(e: Event) =>
            (this.emailOrPhone = (e.target as HTMLInputElement).value)}"
          @change=${this.reportValidity}
        ></md-filled-text-field>
        <md-filled-text-field
          id="password"
          type="password"
          label=${msg("Password")}
          aria-label=${msg("Enter your password")}
          required
          @input="${(e: Event) =>
            (this.password = (e.target as HTMLInputElement).value)}"
        ></md-filled-text-field>
        <div id="LoginError">
          <p>
            Wrong email or password. Try again or
            <a href="${(import.meta as any).env.BASE_URL}signup"
              >create an account</a
            >.
          </p>
        </div>
        <div class="forgot-password">
          <a
            href="#"
            @click=${() => this.requestPasswordResetEmail(this.emailOrPhone)}
            >${msg("Forgot password?")}</a
          >
          <div id="toast" popover>
            <p>
              ${msg(
                "A password reset link has been sent to the email provided",
              )}
            </p>
          </div>
        </div>

          <md-elevated-button id="buttonLogin" @click="${this.login}">
            Sign in
          </md-elevated-button>

          <div class="separator">
            <hr />
            <span>${msg("or")}</span>
            <hr />
          </div>

          <md-elevated-button hasIcon @click=${this.signInWithGoogle}>
            ${googleIcon} ${msg("Continue with Google")}
          </md-elevated-button>
					<md-elevated-button hasIcon  @click=${this.initiateLinkedInLogin}>
            ${linkedinIcon} ${msg("Continue with LinkedIn")}
          </md-elevated-button>
          <md-elevated-button
            href="${(import.meta as any).env.BASE_URL}signup"
            >${msg("New to A10D? Join now")}</md-elevated-button
          >
        </div>
      </div>
    `;
  }
}

