// In user-context.ts

import {createContext} from "@lit/context";
import {User} from "firebase/auth";
import {UserDoc} from "../types/user-doc";

export interface ExtendedUser extends User {
  userDoc?: UserDoc;
}

export const userContext = createContext<ExtendedUser | null>("user");
