// docs for router https://github.com/thepassle/app-tools/blob/master/router/README.md

import {html} from "lit";

if (!(globalThis as any).URLPattern) {
  await import("urlpattern-polyfill");
}
import {Router} from "@thepassle/app-tools/router.js";
import {lazy} from "@thepassle/app-tools/router/plugins/lazy.js";
import {appName} from "@thepassle/app-tools/router/plugins/appName.js";

// @ts-ignore
// import {title} from "@thepassle/app-tools/router/plugins/title.js";
import {checkServiceWorkerUpdate} from "@thepassle/app-tools/router/plugins/checkServiceWorkerUpdate.js";
import "./pages/app-home.js";
import {authGuardPlugin} from "./myGuard";

const baseURL: string = (import.meta as any).env.BASE_URL;

export const router = new Router({
  plugins: [appName("a10d -"), checkServiceWorkerUpdate],
  routes: [
    {
      path: baseURL,
      title: "welcome",
      render: () => html`<app-home></app-home>`,
    },
    {
      path: `${baseURL}about`,
      title: "About",
      plugins: [lazy(() => import("./pages/app-about/app-about.js"))],
      render: () => html`<app-about></app-about>`,
    },
    {
      path: `${baseURL}clean`,
      title: "Layout Page",
      plugins: [lazy(() => import("./pages/template-page/lay-out.js"))],
      render: () => html`<lay-out></lay-out>`,
    },
    {
      path: `${baseURL}layout`,
      title: "Material Layout Page",
      plugins: [lazy(() => import("./pages/template-page/material-layout.js"))],
      render: () => html`<material-layout></material-layout>`,
    },
    {
      path: `${baseURL}profile`,
      title: "Profile",
      plugins: [
        authGuardPlugin,
        lazy(() => import("./pages/user-profile/user-profile.js")),
      ],
      render: () => html`<user-profile></user-profile>`,
    },
    {
      path: `${baseURL}newevent`,
      title: "Add Event",
      plugins: [
        authGuardPlugin,
        lazy(() => import("./pages/events/events-new.js")),
      ],
      render: () => html`<events-new></events-new>`,
    },
    {
      path: `${baseURL}signup`,
      title: "Sign Up",
      plugins: [lazy(() => import("./pages/sign-up-form/sign-up-form.js"))],
      render: () => html`<sign-up-form></sign-up-form>`,
    },
    {
      path: `${baseURL}events`,
      title: "Events",
      plugins: [lazy(() => import("./pages/events/events-list.js"))],
      render: () => html`<events-list></events-list>`,
    },

    {
      path: `${baseURL}events/:industry`,
      title: (context: Partial<{params: {industry?: string}}>) =>
        `${context.params?.industry} Events`,
      plugins: [lazy(() => import("./pages/events/events-list.js"))],
      render: (context: Partial<{params: {industry?: string}}>) =>
        html`<events-list
          .industry="${context.params?.industry}"
        ></events-list>`,
    },
    {
      path: `${baseURL}eventdetails/:eventId`,
      title: (context: Partial<{params: {eventId?: string}}>) =>
        `${context.params?.eventId} Events`,
      plugins: [lazy(() => import("./pages/events/event-details.js"))],
      render: (context: Partial<{params: {eventId?: string}}>) =>
        html`<event-details-screen
          .eventId="${context.params?.eventId}"
        ></event-details-screen>`,
    },
    {
      path: `${baseURL}auth/linkedin`,
      title: "LinkedIn Login",
      plugins: [
        lazy(() => import("./pages/linkedin-auth/linkedin-auth-page.js")),
      ],
      render: () => html`<linkedin-auth-page></linkedin-auth-page>`,
    },
    {
      path: `${baseURL}auth/linkedin/callback`,
      title: "LinkedIn Auth Callback",
      plugins: [
        lazy(
          () => import("./pages/linkedin-auth/linkedin-auth-callback-page.js"),
        ),
      ],
      render: () =>
        html`<linkedin-auth-callback-page></linkedin-auth-callback-page>`,
    },
    {
      path: `${baseURL}privacy`,
      title: "Privacy Policy",
      plugins: [lazy(() => import("./pages/app-privacy/app-privacy.js"))],
      render: () => html`<app-privacy></app-privacy>`,
    },
  ],
});

