import {auth} from "./libs/firebase-app";
import type {Plugin, Context} from "@thepassle/app-tools/router.js";
import {onAuthStateChanged} from "firebase/auth";

const getAuthState = (): Promise<boolean> => {
  console.log("getAuthState called");
  return new Promise((resolve) => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      console.log(
        "Auth state changed, user:",
        user ? "logged in" : "not logged in",
      );
      unsubscribe();
      resolve(!!user);
    });
  });
};

export const authGuardPlugin: Plugin = {
  name: "auth-guard",

  // @ts-ignore
  shouldNavigate: (_context: Context) => {
    return {
      condition: async () => {
        const isAuthenticated = await getAuthState();
        console.log("authGuardPlugin: User authenticated:", isAuthenticated);
        return isAuthenticated;
      },
      redirect: "/",
    };
  },
};


