import { LitElement, html, css } from 'lit';
import { customElement, property} from 'lit/decorators.js';
import { localized } from '@lit/localize';

@localized()
@customElement('revealing-text')
export class RevealingText extends LitElement {
  static styles = css`
    :host {
      display: inline-block;
    }
    .slot-wrapper {
      height: 0;
      overflow: hidden;
    }
    .word {
      opacity: 0;
      animation: reveal 0.5s forwards;
    }

    @keyframes reveal {
      to {
        opacity: 1;
      }
    }
  `;

  @property({ type: String }) text = '';


  // handleSlotChange = () => {
  //   this.words = (this.textContent?.trim() || '').split(' ');
  // };

  handleAnimationEnd = () => {
    this.dispatchEvent(new CustomEvent('all-words-displayed'));
  };

  render() {
    const words = this.text.split(' ');

    return html`
      <div class="slot-wrapper"><slot></slot></div>
      ${words.map(
        (word, index) => html`<span
          class="word"
          style="animation-delay: ${index * 0.75}s"
          @animationend=${index === words.length - 1 ? this.handleAnimationEnd : undefined}
          >${word}
        </span>`
      )}
    `;
  }


}