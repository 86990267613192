import { html, css, LitElement } from 'lit';
import { customElement, property } from 'lit/decorators.js';

@customElement('word-dropper')
export class WordDropper extends LitElement {
  @property({ type: Array })
  words: string[] = ['hello', 'good bye', 'another word', 'and another'];

  index = 0;

  static get styles() {
    return css`
      :host {
        text-align: start;
      }
      .word-container {
        height: 2em;
        overflow: hidden;
        position: relative;
      }
      #word {
        position: absolute;
        animation: drop 1s linear infinite;
      }

      @keyframes drop {
        0% {
          top: -1em;
          opacity: 0;
        }
        10%,
        90% {
          top: 0;
          opacity: 1;
        }
        100% {
          top: 1em;
          opacity: 0;
        }
      }
    `;
  }

  firstUpdated() {
    const wordElement = this.shadowRoot?.getElementById('word');
    if (wordElement) {
      wordElement.textContent = this.words[this.index];
      wordElement.addEventListener('animationiteration', () => {
        this.index = (this.index + 1) % this.words.length;
        wordElement.textContent = this.words[this.index];
      });
    }
  }

  render() {
    return html`
      <div id="word-container" class="word-container">
        <span id="word"></span>
      </div>
    `;
  }
}

