import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';
import { getStorage, ref, getDownloadURL } from 'firebase/storage';
import {
  initializeFirestore,
  // connectFirestoreEmulator,
  persistentLocalCache,
  // persistentSingleTabManager,
  persistentMultipleTabManager,
} from 'firebase/firestore';

import { getFunctions, httpsCallable } from 'firebase/functions';
import { getAuth, signInWithCustomToken } from 'firebase/auth';

// import { getStripePayments } from '@invertase/firestore-stripe-payments';

const firebaseConfig = {
	apiKey: 'AIzaSyD91PfNLVkucy_Zws4t3WyevR6DVv4BiA8',
	authDomain: 'a10d-events.firebaseapp.com',
	projectId: 'a10d-events',
	storageBucket: 'a10d-events.appspot.com',
	messagingSenderId: '402951469838',
	appId: '1:402951469838:web:72e299357835c3b57b755f',
	measurementId: 'G-H4PFH8N51B',
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

const analytics = getAnalytics(app);
const storage = getStorage(app);
const functions = getFunctions(app);
const db = initializeFirestore(app, {
  localCache: persistentLocalCache({
    tabManager: persistentMultipleTabManager(),
  }),
});

// export const payments = getStripePayments(app, {
//   productsCollection: 'products',
//   customersCollection: 'customers',
// });

// export const db = getFirestore(app);

export {
  app,
  analytics,
  auth,
  storage,
  db,
  functions,
  ref,
  getDownloadURL,
  httpsCallable,
  signInWithCustomToken,
};
// Connect to the Firestore emulator.
// Replace 'localhost' and 8080 with your actual host and port if different.
//connectFirestoreEmulator(db, 'localhost', 8080);


