import { db } from './firebase-app.js';
import {
  EventDetails,
  Industry,
  IndustryCounter,
} from '../types/event_interface.js';
import {
  doc,
  setDoc,
  getDoc,
  collection,
  getDocs,
  addDoc,
  onSnapshot,
  query,
  // writeBatch,
} from 'firebase/firestore';


// add a new document in collection "industries"
export const addIndustry = async (industry: Industry) => {
  try {
    await setDoc(doc(db, 'industries', industry.industry), industry);
  } catch (e) {
    console.error('Error adding document: ', e);
  }
};


// Add a new events in collection "events"

/**
 * Updates an event in the database.
 * @param id - The ID of the event to update.
 * @param myEvent - The updated event details.
 */
export const updateEvent = async (id: string, myEvent: EventDetails) => {
  try {
    await setDoc(
      doc(db, 'events', `${id}`),
      {
        ...myEvent,
      },
      { merge: true }
    );
    console.log('Document successfully updated!');
  } catch (e) {
    console.error(e);
  }
};

/**
 * Retrieves event details from the database based on the provided ID.
 * @param id - The ID of the event to retrieve.
 * @returns A promise that resolves to the event details if found, or null if not found or an error occurred.
 */
export const getEvents = async (id: string) => {
  try {
    const docRef = doc(db, 'events', `${id}`);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      console.log('Document data:', docSnap.data());
      const myEvent: EventDetails = {
        ...(docSnap.data() as EventDetails),
      };
      return myEvent;
    } else {
      // doc.data() will be undefined in this case
      console.log('No such document!');
      return null;
    }
  } catch (e) {
    console.error(e);
    return null;
  }
};

/**
 * Retrieves a list of events from the database.
 * @returns {Promise<Array<EventDetails>>} A promise that resolves to an array of EventDetails objects.
 */
export const listEvents = async () => {
  try {
    const querySnapshot = await getDocs(collection(db, 'events'));
    const events: Array<EventDetails> = [];
    querySnapshot.forEach((doc) => {
      const myEvent: EventDetails = {
        ...(doc.data() as EventDetails),
      };
      events.push(myEvent);
    });
    return events;
  } catch (e) {
    console.error(e);
    return [];
  }
};

/**
 * Retrieves a snapshot of events from the database and invokes the provided callback function with the events.
 * @param callback - The callback function to be invoked with the retrieved events.
 * @returns A function to unsubscribe from the snapshot listener.
 */
export const eventsSnapshot = (callback: (events: EventDetails[]) => void) => {
  const eventsQuery = query(collection(db, '/events'));
  const unsubscribe = onSnapshot(eventsQuery, (snapshot) => {
    let events: EventDetails[] = [];
    snapshot.forEach((doc) => {
      const myEvent: EventDetails = {
        ...(doc.data() as EventDetails),
        id: doc.id,
      };
      events.push(myEvent);
    });
    callback(events);
  });

  return unsubscribe;
};

// create a live subscription of industry data with counters
/**
 * Retrieves a snapshot of industries from the database and invokes the provided callback function with the industries.
 * @param callback - The callback function to be invoked with the retrieved industries.
 * @returns A function to unsubscribe from the snapshot listener.
 */
export const industriesSnapshot = (
  callback: (industries: IndustryCounter[]) => void) => {
  const industriesQuery = query(collection(db, '/industries'));
  const unsubscribe = onSnapshot(industriesQuery, (snapshot) => {
    let industries: IndustryCounter[] = [];
    snapshot.forEach((doc) => {
      const myIndustry: IndustryCounter = {
        ...(doc.data() as IndustryCounter),
      };
      industries.push(myIndustry);
    });
    callback(industries);
  });

  return unsubscribe;
};

/**
 * Adds an event to the database.
 * @param eventDetail - The details of the event to be added.
 */
export const addEvent = async (eventDetail: EventDetails) => {
  try {
    const docRef = await addDoc(collection(db, 'events'), eventDetail);
    console.log('Document written with ID: ', docRef.id);
  } catch (e) {
    console.error('Error adding document: ', e);
  }
};

