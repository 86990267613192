import {LitElement, css, html} from "lit";
import {property, customElement, state, query} from "lit/decorators.js";
import {consume} from "@lit/context";
import type {User} from "firebase/auth";
import {auth} from "../libs/firebase-app.js";
import {userContext} from "../context/user-context.js";
import {msg, localized} from "@lit/localize";
import {getLocale, setLocale} from "../libs/localise.js";
import {repeat} from "lit-html/directives/repeat.js";

import "@material/web/iconbutton/filled-icon-button.js";
import "@material/web/icon/icon.js";
import "@material/web/menu/menu.js";
import "@material/web/menu/menu-item.js";
import "@material/web/menu/sub-menu.js";
import "@material/web/dialog/dialog.js";
import "@material/web/select/filled-select.js";
import "@material/web/select/select-option.js";
import type {MdDialog} from "@material/web/dialog/dialog.js";
import type {MaterialNavigationDrawer} from "../components/material-navigation-drawer.js";

// import './auth-phone.js';
import type {Menu} from "@material/web/menu/internal/menu.js";

@customElement("app-header")
@localized()
export class AppHeader extends LitElement {
  @property({type: String}) title = "";
  @property({type: Boolean}) enableBack = false;
  @query("material-navigation-drawer") drawer!: MaterialNavigationDrawer;

  @consume({context: userContext, subscribe: true})
  @state()
  user: User | null = null;

  @state()
  showLogin = false;

  private _currentLanguage: string = getLocale();
  // @state()
  // provider = new GoogleAuthProvider();

  @query("md-menu") menu!: HTMLElement;
  @query("md-menu-item") menuItem!: HTMLElement;
  @query("#personal-menu-button") _usageAnchor!: HTMLElement;
  @query("#personal-menu") _menuEl!: Menu;
  @query("#LangDialog") _langDialog!: MdDialog;

  private _languages = [
    {code: "ar", name: "العربية"},
    {code: "bg", name: "Български"},
    {code: "cs", name: "Čeština"},
    {code: "da", name: "Dansk"},
    {code: "de", name: "Deutsch"},
    {code: "el", name: "Ελληνικά"},
    {code: "en", name: "English"},
    {code: "es", name: "Español"},
    {code: "fi", name: "Suomi"},
    {code: "fr", name: "Français"},
    {code: "ga", name: "Gaeilge"},
    {code: "hr", name: "Hrvatski"},
    {code: "hu", name: "Magyar"},
    {code: "is", name: "Íslenska"},
    {code: "it", name: "Italiano"},
    {code: "lv", name: "Latviešu"},
    {code: "mt", name: "Malti"},
    {code: "nl", name: "Nederlands"},
    {code: "no", name: "Norsk"},
    {code: "pl", name: "Polski"},
    {code: "pt", name: "Português"},
    {code: "ro", name: "Română"},
    {code: "sk", name: "Slovenčina"},
    {code: "sl", name: "Slovenščina"},
    {code: "sv", name: "Svenska"},
    {code: "uk", name: "Українська"},
  ];

  static get styles() {
    return css`
      #menu {
        box-sizing: border-box;

        background: var(--md-sys-color-primary, #db8d00);
        color: var(--md-sys-color-on-primary, white);

        left: env(titlebar-area-x, 0);
        top: env(titlebar-area-y, 0);
        height: env(titlebar-area-height, 50px);
        width: env(titlebar-area-width, 100vw);
        -webkit-app-region: drag;
        z-index: 500;
        box-sizing: border-box;
        padding: 1rem;

        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        position: sticky;
      }
      md-menu-item[disabled] {
        opacity: 0.38;
        cursor: not-allowed;
      }

      #back-button-block {
        width: auto;
      }
      .app-title {
        font-size: 1.5rem;
        font-family: "Orbitron";
        margin: 0;
      }

      .icons {
        justify-content: right;
        display: flex;
      }
    `;
  }

  // private handleLoginClick() {
  //   // TODO: Implement login/logout logic
  //   // this.user ? auth.signOut() : this.Googlelogin();
  //   this.user ? auth.signOut() : this.PhoneLogin();
  // }

  PhoneLogin() {
    // auth.useDeviceLanguage();
    auth.languageCode = this._currentLanguage;
    if (this.user) {
      auth.signOut();
      return;
    }

    this.showLogin = true;
  }

  userImage() {
    if (!this.user) return;
    if (!this.user.photoURL) {
      return html`<span>${this.user.displayName}</span>`;
    }
    return this.user
      ? html`<img src="${this.user.photoURL}" alt="User Image" />`
      : "Not logged in";
  }

  ShowMenu() {
    this.drawer.toggleDrawer();

    //trigger a custom event when the menu is clicked
    // this.dispatchEvent(new CustomEvent('menu-clicked', { bubbles: true }));
  }
  showPersonalMenu() {
    this._currentLanguage = getLocale();
    this._menuEl.open = !this._menuEl.open;
  }
  navigateToProfile() {
    // Replace this with your SPA's routing method if available
    window.location.href = "/profile";
  }

  render() {
    return html`
        <material-navigation-drawer></material-navigation-drawer>
        <div id="menu">
            <div id="back-button-block">
                <md-filled-icon-button
                    id="menu-anchor"
                    @click=${this.ShowMenu}
                    aria-label="Open menu"
                >
                    <md-icon>menu</md-icon>
                </md-filled-icon-button>
            </div>
            <div class="app-title" role="heading" aria-level="1">a10d</div>

            <div class="icons">
                <span style="position: relative">
                    <md-filled-icon-button
                        id="personal-menu-button"
                        @click=${this.showPersonalMenu}
                        aria-haspopup="true"
                        aria-controls="personal-menu"
												aria-label="Open personal menu"
                    >
                        <md-icon>more_vert</md-icon>
                    </md-filled-icon-button>
                    <md-menu
                        has-overflow
                        id="personal-menu"
                        anchor="personal-menu-button"
                        aria-labelledby="personal-menu-button"
                    >
                        <md-menu-item>
                            <div
                                slot="headline"
                                @click=${() =>
                                  (this._langDialog.open =
                                    !this._langDialog.open)}
                                tabindex="0"
                                aria-label="Change language"
                            >
                                ${msg("Language")}
                            </div>
                            <md-icon slot="start">language</md-icon>
                        </md-menu-item>

                        <md-menu-item
                            ?disabled=${!this.user}
                            href="/profile"
                        >
                            ${msg("Profile")}
                            <md-icon slot="start">person</md-icon>
                        </md-menu-item>
                        <md-menu-item
                        >${msg("Settings")}
                            <md-icon slot="start">settings</md-icon>
                        </md-menu-item>
                        <md-menu-item @click=${this.PhoneLogin}
                        >
                            ${
                              this.user
                                ? html`${msg("Logout")}
                                    <md-icon slot="start">logout</md-icon>`
                                : html`${msg("Login")}<md-icon slot="start"
                                      >login</md-icon
                                    >`
                            }
                        </md-menu-item>
                    </md-menu>
                </span>
                <md-dialog id="LangDialog" role="dialog" aria-modal="true" aria-labelledby="LangDialogTitle">
                    <span slot="headline" id="LangDialogTitle">${msg(
                      "Select your language",
                    )}</span>
                    <md-filled-select
                        slot="content"
                        @change=${(e: Event) => {
                          const target = e.target as HTMLInputElement;
                          this._currentLanguage = target.value;
                          setLocale(target.value);
                        }}
                        aria-label="Language selection"
                    >
                        ${repeat(
                          this._languages,
                          (lang) => lang.code, // Use the language code as the unique identifier for each item
                          (lang) => html`
                            <md-select-option
                              value=${lang.code}
                              ?selected=${lang.code === this._currentLanguage}
                            >
                              ${lang.name}
                            </md-select-option>
                          `,
                        )}
                    </md-filled-select>
                </md-dialog>
            </div>
        </div>
    `;
  }
}
