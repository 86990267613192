import { configureLocalization } from '@lit/localize';
// Generated via output.localeCodesModule
import { sourceLocale, targetLocales } from '../generated/locale-codes.js';
const swapLocale = async (locale: string) => {
  const module = import(`../generated/locales/${locale}.ts`);
  return module;
};

export const { getLocale, setLocale } = configureLocalization({
  sourceLocale,
  targetLocales,
  loadLocale: async (locale) => swapLocale(locale),
});
