import {LitElement} from "lit";
import {customElement, state} from "lit/decorators.js";
import {provide} from "@lit/context";
import {auth} from "./libs/firebase-app.js";
import {onAuthStateChanged} from "firebase/auth";
import {userContext, ExtendedUser} from "./context/user-context.js";
import {getUserDoc} from "./libs/user-doc-operations.js";
import {industriesContext} from "./context/industry-context.js";
import {
  occupationsContext,
  type occupationLookup,
  iscoGroupsContext,
  type iscoLookup,
  isicDataContext,
  type isicData,
  naceDataContext,
  naceLookup,
} from "./context/occupations-context.js";
import {loadResources} from "./libs/loadResources.js";
import {industriesSnapshot, eventsSnapshot} from "./libs/eventData.js";
import {eventsContext} from "./libs/events-context.js";
import "./pages/app-home";
import "./components/app-header.js";
import "./styles/global.css";
import {router} from "./router";
import type {IndustryCounter} from "./types/event_interface.js";

// import {searchService} from "./libs/search-service.js";

import {setLocale} from "./libs/localise.js";

@customElement("app-index")
export class AppIndex extends LitElement {
  @provide({context: userContext})
  @state()
  user: ExtendedUser | null = null;

  @provide({context: industriesContext})
  industries: IndustryCounter[] = [];

  @provide({context: eventsContext})
  events: any[] = [];

  @provide({context: occupationsContext})
  occupations: occupationLookup = {};

  @provide({context: iscoGroupsContext})
  iscoGroups: iscoLookup = {};

  @provide({context: isicDataContext})
  isicData: isicData = {};

  @provide({context: naceDataContext})
  naceData: naceLookup = {};

  @state()
  isUserLoading: boolean = true;

  private unsubscribeIn: (() => void) | undefined;
  private unsubscribeEv: (() => void) | undefined;

  // static get styles() {
  //   return css``;
  // }

  constructor() {
    super();

    const preferredLanguage = localStorage.getItem("preferredLanguage");
    const browserLanguage = navigator.language.slice(0, 2);

    if (preferredLanguage && preferredLanguage !== "en") {
      setLocale(preferredLanguage);
    } else if (browserLanguage !== "en") {
      setLocale(browserLanguage);
    }

    onAuthStateChanged(auth, async (user) => {
      this.isUserLoading = true;
      if (user) {
        const userDoc = await getUserDoc(user.uid);

        this.user = {
          ...user,
          userDoc: userDoc || undefined,
        };
      } else {
        this.user = null;
        console.log("user not signed in");
      }
      this.isUserLoading = false;
    });
  }

  connectedCallback() {
    super.connectedCallback();
    window.addEventListener("lit-localize-status", (event) =>
      this.localeChange(event),
    );

    // call the industriesSnapshot function to populate the indistries context
    this.unsubscribeIn = industriesSnapshot((industries) => {
      this.industries = industries;
      console.log("industries", industries);
    });
    this.unsubscribeEv = eventsSnapshot((events) => {
      this.events = events;
    });

    this.loadOccupations(navigator.language.split("-")[0]); // }
  }

  localeChange(event: CustomEvent) {
    const status = event.detail.status;
    console.log(`locale status: ${status}`);

    if (status === "ready") {
      const locale = event.detail.readyLocale;
      this.loadOccupations(locale);
      // console.log('locale loaded', locale);
      localStorage.setItem("preferredLanguage", locale);
    }
  }

  async loadOccupations(locale: string) {
    const {iscoGroupsData, occupationsData, isicData, naceData} =
      await loadResources(locale);
    this.occupations = occupationsData as occupationLookup;
    this.iscoGroups = iscoGroupsData as iscoLookup;
    this.isicData = isicData as isicData;
    this.naceData = naceData as naceLookup;
    // searchService.createIndex(locale, this.naceData);
    localStorage.setItem("preferredLanguage", locale);
  }

  disconnectedCallback() {
    super.disconnectedCallback();
    window.removeEventListener("lit-localize-status", (event) =>
      this.localeChange(event),
    );
    if (this.unsubscribeIn) {
      this.unsubscribeIn(); // stop listening for changes when the element is disconnected
    }
    if (this.unsubscribeEv) {
      this.unsubscribeEv(); // stop listening for changes when the element is disconnected
    }
  }

  firstUpdated() {
    router.addEventListener("route-changed", () => {
      if ("startViewTransition" in document) {

        return (document as any).startViewTransition(() => {
          console.log('start view transition');
          this.requestUpdate();
        });
      }
      this.requestUpdate();
    });
  }

  render() {
    // router config can be round in src/router.ts
    return router.render();
  }
}

