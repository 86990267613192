import {LitElement, html, css} from "lit";
import {customElement, property, state} from "lit/decorators.js";
import {consume} from "@lit/context";
import {classMap} from "lit-html/directives/class-map.js";
import "@material/web/icon/icon";
import "@material/web/list/list";
import "@material/web/list/list-item";
import "@material/web/button/elevated-button";
import {User} from "firebase/auth";
import {userContext} from "../context/user-context.js";
import {msg} from "@lit/localize";

@customElement("material-navigation-drawer")
export class MaterialNavigationDrawer extends LitElement {
  @property({type: Boolean, reflect: true})
  open: boolean = false;
  @property({type: Boolean, reflect: true})
  fixed: boolean = false;
  @consume({context: userContext, subscribe: true})
  @state()
  user: User | null = null;

  static styles = css`
    :host {
      --drawer-width: 256px; /* Default width */
      --background-color: var(--md-sys-color-secondary, rgb(22 102 131));
      --text-color: var(--md-sys-color-on-secondary, rgb(255 255 255));
      --hover-color: var(--md-sys-color-primary, #6200ee);
      --icon-color: var(--md-sys-color-on-secondary, rgb(255 255 255));
      --divider-color: var(--md-sys-color-outline, rgba(0, 0, 0, 0.12));

      --md-list-container-color: var(--md-sys-color-secondary, rgb(22 102 131));
      --md-list-item-label-text-color: var(
        --md-sys-color-on-secondary,
        rgb(255 255 255)
      );
      --md-list-item-supporting-text-color: var(
        --md-sys-color-on-secondary,
        rgb(255 255 255)
      );
      --md-list-item-leading-image-height: 80px;
      --md-list-item-leading-image-width: 80px;
      --md-list-item-leading-image-shape: 25px;
      --md-list-item-leading-icon-color: var(
        --md-sys-color-on-secondary,
        rgb(255 255 255)
      );
      --box-shadow: var(
        --md-sys-elevation-level2,
        0px 2px 1px -1px rgba(0, 0, 0, 0.2),
        0px 1px 1px 0px rgba(0, 0, 0, 0.14),
        0px 1px 3px 0px rgba(0, 0, 0, 0.12)
      );
      --scrim: var(--md-sys-scrim, rgba(0, 0, 0, 0.32));
    }
    md-list.disabled {
      pointer-events: none;
      opacity: 0.5;
    }
    .drawer {
      width: var(--drawer-width);
      height: 100vh;
      position: fixed;
      top: 0;
      left: 0;
      transform: translateX(-100%);
      transition: transform 0.3s ease;
      background-color: var(--background-color);
      color: var(--text-color);
      box-shadow: var(--box-shadow);
      z-index: 1000;
      border-radius: 0 16px 16px 0;
    }
    :host([fixed]) .drawer {
      width: 80px;
      top: env(titlebar-area-height, 50px);
      z-index: 10;
    }

    :host([fixed]) md-list-item {
      [slot="headline"],
      [slot="supporting-text"],
      [slot="trailing-supporting-text"] {
        display: none;
      }
    }

    .scrim {
      position: fixed;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background-color: var(--scrim);
      z-index: 999; /* Ensure the scrim is above other elements */
      display: none; /* Initially hidden */
    }

    :host([open]) .drawer {
      transform: translateX(0);
    }

    :host([open]) .scrim {
      display: block;
    }

    :host:not([open]) .drawer {
      transform: translateX(-100%);
    }
    nav {
      display: flex;
      flex-direction: column;
      height: 100%;
    }

    md-elevated-button {
      margin-top: auto;
      margin-left: 16px;
      margin-right: 16px;
      margin-bottom: 16px;
    }

    .nav-item {
      display: flex;
      align-items: center;
      height: 56px;
      padding: 0 16px;
      font-size: 16px;
      color: var(--icon-color);
      text-decoration: none;
      transition: background-color 0.3s;
    }

    .nav-item:hover {
      background-color: var(--hover-color);
      color: var(--background-color);
    }
    .divider {
      border-bottom: 1px solid var(--divider-color);
    }
    .headline {
      padding: 16px;
      font: var(--md-sys-typescale-title-small-font);
      font-size: var(--md-sys-typescale-title-small-size, 1.16rem);
      font-weight: 500;
      color: var(--md-sys-color-on-surface-variant);
    }
  `;

  toggleDrawer() {
    this.open = !this.open;
  }

  render() {
    const classes = {disabled: !this.user};

    return html`
      <!-- If not fixed, render scrim -->
      ${!this.fixed
        ? html`<div
            id="scrim"
            class="scrim"
            @click=${this.toggleDrawer}
            aria-hidden="true"
          ></div>`
        : ""}

      <div class="drawer">
        <nav role="navigation">
          <md-list class=${classMap(classes)}>
            <a href="/" role="button" aria-label=${msg("Home")}>
              <md-list-item id="li_home">
                <div slot="headline">${msg("Ooops")}</div>
                <md-icon slot="start">home</md-icon>
                <div slot="supporting-text">
                  ${msg("Lots of text about home here")}
                </div>
              </md-list-item>
            </a>
            <a href="/events" role="button" aria-label=${msg("My Events")}>
              <md-list-item id="li_myEvents">
                <div slot="headline">${msg("My Events")}</div>
                <md-icon slot="start">calendar_clock</md-icon>
                <div slot="supporting-text">
                  ${msg("Lots of text about calendar here")}
                </div>
              </md-list-item>
            </a>
            <div role="button" aria-label=${msg("Messages")} tabindex="0">
              <md-list-item id="li_messages">
                <div slot="headline">${msg("Messages")}</div>
                <md-icon slot="start">chat</md-icon>
                <div slot="supporting-text">${msg("you can chat here")}</div>
              </md-list-item>
            </div>
          </md-list>

          ${!this.user
            ? html` <md-elevated-button id="signin"
                aria-label=${msg("Sign In / Register")}
                href="${(import.meta as any).env.BASE_URL}signup"
                >${msg("Sign In / Register")}</md-elevated-button
              >`
            : ""}
        </nav>
      </div>
    `;
  }
}


