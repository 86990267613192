import { createContext } from '@lit/context';
export type occupationLookup = {
    [key: string]: string;
} | null;

export type iscoLookup = {
    [key: string]: string;
} | null;

export type isicData = {
  [key: string]: {
    title: string;
    includes: string;
    excludes: string;
  };
};

export type naceLookup = {
  [key: string]: {
    label: string;
    description: string;
  };
};

export const occupationsContext = createContext<occupationLookup>({});
export const isicDataContext = createContext<isicData>({});
export const naceDataContext = createContext<naceLookup>({});
export const iscoGroupsContext = createContext<iscoLookup>({});