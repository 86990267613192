import { LitElement, css, html } from 'lit';
import { customElement, state, query } from 'lit/decorators.js';
import { consume } from '@lit/context';
import { ifDefined } from 'lit/directives/if-defined.js';
import {
  type IndustryCounter,
  industriesContext,
} from '../context/industry-context.js';
import { User } from 'firebase/auth';
import { userContext } from '../context/user-context.js';

import '@material/web/button/elevated-button.js';
import '../components/auth-panel.js';
import '../components/revealing-text.js';
import '../components/word-dropper.js';
import '../components/material-navigation-drawer.js';
import { MaterialNavigationDrawer } from '../components/material-navigation-drawer.js';
import { msg, localized } from '@lit/localize';
import { getLocale } from '../libs/localise.js';

// interface MaterialNavigationDrawerElement extends HTMLElement {
//   toggleDrawer: () => void;
// }

@customElement('app-home')
@localized()
export class AppHome extends LitElement {
   static get styles() {
    return [
      css`
        /* Generic styles */
        html {
          scroll-behavior: smooth;
        }

        /* Styles for the hero section */
        .hero {
          box-sizing: border-box;
          background-color: var(--md-sys-color-primary-container, #db8d00);
          color: var(--md-sys-color-on-primary-container, white);
          padding: 0 0.5rem 0.5rem;
          display: grid;
          grid-template-columns: 1fr;
          justify-content: center;
          align-items: center;
        }

        h1 {
          font-size: calc(1rem + 2vw);
          font-weight: 700;
          margin-top: 0.5rem;
          margin-bottom: 0.5rem;
        }

        a {
          color: var(--md-sys-color-secondary);
          text-decoration: none;
        }

        a:hover {
          opacity: 0.6;
        }

        auth-panel {
          justify-self: center;
          width: 100%;
        }

        /* footer */
        footer {
          background-color: var(--md-sys-color-inverse-surface);
          padding: 0.75rem;
          color: var(--md-sys-color-inverse-on-surface);
          text-align: center;
          font-size: 0.75rem;
        }

        #welcomeBar {
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
        }

        #welcomeCard,
        #infoCard {
          padding: 18px;
          padding-top: 0px;
        }

        /* Adjustments for larger screens */
        @media (min-width: 481px) {
          .hero {
            padding: 0 1rem 1rem;
          }

          h1 {
            font-size: calc(1.2rem + 2vw);
          }
        }

        @media (min-width: 720px) {
          .hero {
            grid-template-columns: minmax(260px, 1fr) 1fr;
            grid-gap: 12px;
          }

          .hero > h1 {
            grid-column: 1 / -1;
          }

          h1 {
            font-size: calc(1.5rem + 2vw);
            margin-top: 0;
            margin-bottom: 0;
          }

          sl-card {
            width: 70vw;
          }
        }

        @media (min-width: 960px) {
          .hero {
            grid-template-columns: minmax(480px, 1fr) 1fr;
          }

          h1 {
            font-size: calc(1.7rem + 2vw);
          }
        }

        @media (horizontal-viewport-segments: 2) {
          #welcomeBar {
            flex-direction: row;
            align-items: flex-start;
            justify-content: space-between;
          }

          #welcomeCard {
            margin-right: 64px;
          }
        }
      `,
    ];
  }

  @consume({ context: industriesContext, subscribe: true })
  industries: IndustryCounter[] | undefined;

  @consume({ context: userContext, subscribe: true })
  @state()
  user: User | null = null;

  /**
   * Indicates whether all text is displayed or not
   * before we start the second animation.
   */
  @state() all_text_displayed: boolean = false;
  @query('material-navigation-drawer') drawer!: MaterialNavigationDrawer;



  handleMenuClicked() {
    this.drawer.toggleDrawer();
  }

  share() {
    if ((navigator as any).share) {
      (navigator as any).share({
        title: `${msg('a10d - Networking Made Easy')}`,
        text: `${msg("Check out this cool App that I'm using!")}`,
        url: `${(import.meta as any).env.BASE_URL}`,
      });
    }
  }

  showdropper() {
    this.all_text_displayed = true;
  }

  render() {
    const rtl = getLocale() === 'ar';
    const dir = rtl ? 'rtl' : undefined;

    let ctaPrompt = msg('Sign In / Register', {
      desc: 'in the context of registering or logging in to a service',
    });
    let URL = `${(import.meta as any).env.BASE_URL}register`;
    if (this.user) {
      ctaPrompt = msg('browse events', {
        desc: 'in the context of viewing a list gatherings or business meetings',
      });
      URL = `${(import.meta as any).env.BASE_URL}events`;
    }

    return html`
      <app-header @menu-clicked=${this.handleMenuClicked}></app-header>
      <material-navigation-drawer></material-navigation-drawer>
      <section dir=${ifDefined(dir)} class="hero">
        <h1>
          ${msg("Welcome to")} a10d:
          ${msg("Empowering your networking experience")}
        </h1>

        ${this.user
          ? html`<h2>${msg("Hello")} ${this.user.displayName}</h2>`
          : html`<auth-panel></auth-panel>`}

        <article>
          <h1>
            <revealing-text
              @all-words-displayed=${this.showdropper}
              .text=${msg("Catch Up With", {
                desc: "in the context of getting back in touch or the latest information",
              })}
            >
            </revealing-text>
            ${this.all_text_displayed
              ? html` <word-dropper
                  .words=${[
                    msg("Contacts"),
                    msg("Opportunities"),
                    msg("Clients", {
                      desc: "in the context of business relationships",
                    }),
                    msg("Networks", {
                      desc: "in the context of social or business connections",
                    }),
                    msg("Leads", {
                      desc: "in the context of sales opportunites",
                    }),
                    msg("Professionals"),
                    msg("Business"),
                    msg("Events", {
                      desc: "in the context of social gatherings or business meetings",
                    }),
                  ]}
                ></word-dropper>`
              : html`<div style="height: 2em"></div>`}
          </h1>
          <h3>
            ${msg(
              "Connect with business professionals and like-minded individuals without the hefty price tag",
            )}
          </h3>
          <p>
            ${msg(
              "A10D is tailored for business professionals who are promoting their products or services and seeking both formal and informal networking opportunities. Whether you’re looking to host a product demo, swap business contacts, or simply go running with peers, our platform facilitates it all. Explore and create events locally and across borders, engaging in activities that maximise both your professional and personal potential. Join A10D today and start building valuable connections that meet you where you are.",
            )}
          </p>
          <md-elevated-button
            id="cta"
            style="float: right;"
            aria-label=${ctaPrompt}
            href=${URL}
            >${ctaPrompt}</md-elevated-button
          >
        </article>
      </section>

      <main dir=${ifDefined(dir)}>
        <div id="welcomeBar">
          <sl-card id="welcomeCard">
            <div slot="header">
              <h2>
                ${msg("Are you missing out?", {
                  desc: "in the context of business opportunities or social gatherings",
                })}
              </h2>
            </div>

            <p>
              ${msg(
                `Are you missing out on valuable networking opportunities due to the high cost of attending large events? Struggling to find a convenient way to connect with like-minded professionals without breaking the bank? Our app provides the perfect solution to bridge this gap and help you expand your network.`,
              )}
            </p>
            <p>
              ${msg(
                `Discover a seamless platform designed to bring professionals together for social gatherings, contact exchanges, and accessible local activities that complement major events. Unlock your business potential and forge meaningful connections with our easy-to-use app, specifically tailored to meet your networking needs.`,
              )}
            </p>

            ${"share" in navigator
              ? html`<md-elevated-button
                  aria-label=${msg("share this platform")}
                  @click="${this.share}"
                >
                  ${msg("share this platform")}
                </md-elevated-button>`
              : null}
          </sl-card>

          <sl-card id="infoCard">
            <div slot="header">
              <h2>
                ${msg("Latest Events", {
                  desc: "in the context of social gatherings or business meetings",
                })}
              </h2>
            </div>

            <ul>
              ${this.industries
                ? this.industries
                    .filter((industry) => industry.count > 0)
                    .sort((a, b) => b.count - a.count)
                    .map(
                      (industry) => html`
                        <li>
                          <button name="arrow-right-circle"></button>
                          <a
                            href="${(import.meta as any).env
                              .BASE_URL}events/${industry.industry}"
                            >${industry.industry}</a
                          >
                          ${industry.count}
                        </li>
                      `,
                    )
                : null}
            </ul>
          </sl-card>

          <button
            href="${(import.meta as any).env.BASE_URL}about"
            variant="primary"
          >
            ${msg("Navigate to About", {
              desc: "in the context of a page about this application or company",
            })}
          </button>
        </div>
      </main>
      <footer dir=${ifDefined(dir)}>
        <p>
          &copy; 2024 a10d. ${msg("All rights reserved.")} |
          ${msg("Privacy Policy")} | ${msg("Terms of Service")}
        </p>
      </footer>
    `;
  }
}



